$(document).ready(function(){
  $('.faq .single .faq__answear').hide();

  $('.faq .single.active .faq__answear').slideDown();

  $('.faq .single .faq__question').click(function() {
    var parent = $(this).parent();
    var mother = $(parent).parent();
    var faqid = $(mother).attr('data-faq');
    var separate = $(mother).parent();
    var separateID = $(separate).attr('data-faq');
    var qid = $(this).attr('data-question');

    if ($(separate).hasClass("faq--separate")) {
      $('.faq[data-faq="'+ separateID +'"] .faq__answear').slideUp();
      if ($(parent).hasClass("active")) {
        $('.faq[data-faq="'+ separateID +'"] .single.active').removeClass("active");
        $(parent).removeClass("active");
        $(separate).find('.faq__answear-list [data-question="'+ qid +'"]').slideUp(); ;
      } else {
        $('.faq[data-faq="'+ separateID +'"] .single.active').removeClass("active");
        $(parent).addClass("active");
        $(separate).find('.faq__answear-list [data-question="'+ qid +'"]').parent().addClass("active");
        $(separate).find('.faq__answear-list .single.active .faq__answear').slideDown(); ;
      }
    } else {
      if ($(parent).hasClass("active")) {
        $(parent).removeClass("active").find(".faq__answear").slideUp();
      } else {
        if ($(mother).hasClass("accordion")) {
          $('.faq[data-faq="'+ faqid +'"] .single.active .faq__answear').slideUp();
          $('.faq[data-faq="'+ faqid +'"] .single.active').removeClass("active");
          $(parent).addClass("active").find(".faq__answear").slideDown();  
        } else {
            $(parent).addClass("active").find(".faq__answear").slideDown();
        }
      }
    }
    return false;
  });
  
});