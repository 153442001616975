import LazyLoad from "vanilla-lazyload";
require("./rwd-checker");
require("./form-animation");

$(function() {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // aby użyć do elementów dodajemy klasę .lazy
    // zamiast src w img podajemy data-src
    // zamiast background-image podajemy data-bg
  });

  require("./cookie-bar");
  require("./animations");
  //require("./counter-animation");
  require("./dropdown-menu");
  require("./mobile-menu");
  require("./sticky-header");
  require("./scrolltop");
  require("./pop-up");
  //require("./parallax");
  //require("./leaflet.markercluster");
  require("./faq");

  // $(".gallery").each(function() {
  //   $(this)
  //     .find("a")
  //     .simpleLightbox();
  // });
  
  // if ($(".simplelightbox").length > 0) {
  //   $(".simplelightbox").simpleLightbox();
  // }

  //let text = [];

  // $(document).ready(function () {
  //   // Move current language from dropdown list
  //   $( ".lang-switcher" ).each(function() {
  //     var list = $(this).find( ".list .current" );
  //     $(list).appendTo($(this).find('.selected'));
  //   });
  
  //   // Show / Hide  list of languages
  //   $(".lang-switcher").on('click', function (event) {
  //     $(this).find('.list').fadeToggle('fast');
  //     $(this).toggleClass('lang-switcher--opened');
  //   });
  // });
  
  // // Close when clicking outside
  // $(document).bind('click', function(e){
  //   if (! $(e.target).parents().hasClass("lang-switcher")) {
  //     $(".lang-switcher .list").fadeOut();
  //     $(".lang-switcher").removeClass('lang-switcher--opened');
  //   }
  // });
});
